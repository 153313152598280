@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Space+Grotesk:wght@300..700&display=swap');
:root{
  --font-family: "Inter";
  --font-size: 2rem;
  --font-color: #DADAF2;
  --font-color-tree-hover: #9146FF;
  --font-color-tree:#e2e1e0;
  --font-color-link-tree-inside: #5b5b5b;
  --text-color-link-tree: #000000;
  --font-size-footer: 0.9rem;
  --font-color-footer:#c6c6c6;
}
body{
  /* height: 100vh; */
  background: linear-gradient(to bottom, black, #4B4B4B);
  font-family: var(--font-family);
  overflow-x: hidden;
  
   /* background-color:black; */
}
body::-webkit-scrollbar{
  width: 2px;
}
body::-webkit-scrollbar-thumb{
  background-color: #c6c6c6;
}
