.home-img{
  display: flex;
  justify-content: center;
  align-items: center;
flex-direction: column;
text-align: center;
}
.home-image-container{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #373737;
  object-fit: cover;
}
.home-image-container div img{
  object-fit: contain;
  height: 5.5rem;
  width: 5.5rem;
  margin: 0.4rem;
}
h1,h3{
  color:var(--font-color);
  line-height: 0.5rem;
  white-space: nowrap;
}
h3{
  font-weight: 500;
  margin-bottom: 2rem;
}

.inside-header-main{
  display: flex;
}
.h1-container img{
  /* position: relative; */
  align-self: center;
  margin: 0.5rem;
  width: 1rem;
  height: 1rem;
}
.social-media img{
  width: 32px;
  height: 32px;
  margin: 0.5rem;
}
.message-p p{
  color: var(--font-color);
  width: 20rem;
}
.linkTree{
  margin-top: 2rem;
}
.linkTree .first-Tree:hover{
  background-color: var(--font-color-tree-hover);

}
.linkTree .first-Tree{
  width: 32rem;
  height: 3.5rem;
  border-radius: 2rem;
  background-color: var(--font-color-tree);
  margin-bottom: 2.5rem;
  cursor: pointer;
  position: relative;
  box-shadow: rgb(255 255 255 / 20%) 0px 8px 24px;
}
.first-Tree a{
  text-decoration: none;
  color: var(--text-color-link-tree);
  font-weight: 600;
  
}
.first-link-img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0.2rem 0.2rem 0.2rem 0.3rem;
  background-color: var(--font-color-link-tree-inside);
  position: absolute;
}
.first-link-img img{
    display: flex;
    margin: 0.8rem;
    object-fit: cover;;
}
.linkTree-Text{
  text-align: center;
  padding: 1rem;
  font-size: 1.1rem;
}
@media screen and (max-width:600px) {
  .linkTree .first-Tree{
    width: 90vw;
  }
}